<template>
  <b-sidebar
    id="edit-user-sidebar"
    hide-footer
    right 
    ref="editUserSidebar"
    bg-variant="white"
    backdrop
    @shown="sidebarShow()"
  >
    <div class="p-2">
      <h2>Update User</h2>
      <validation-observer ref="editUserFormValidation">
        <b-form @submit.prevent>
          <b-form-row>
            <b-col md="12">
              <b-form-group label-for="username">
                <template #label>
                  Username
                  <span class="text-danger">* </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  :rules="{ required, regex: /^[A-Z0-9]*$/i }"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Username"
                    @keydown="checkMaxLength($event, 'username', 100)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
            <b-col md="12">
              <b-form-group label-for="fullName">
                <template #label>
                  Full Name <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Full Name"
                  :rules="{ required }"
                >
                  <b-form-input
                    id="fullName"
                    v-model="name"
                    v-restrict="regex"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Full Name"
                    @keydown="checkMaxLength($event, 'full_name', 200)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
            
          </b-form-row>
          <b-form-row>
            <b-col md="12">
              <b-form-group label-for="email">
                <template #label>
                  Email <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Email"
                    @keydown="checkMaxLength($event, 'email', 100)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="user_type">
                <template #label>
                  User Type <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="UserType"
                >
                  <v-select label="name" @input="fetchData()" v-model="user_type" placeholder="Select Option" :options="user_types" :reduce="option => option.id" autoscroll :clearable="true"></v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <span class="text-colorBlack"
                ></span
              >
              <b-form-group class="mb-0">
              </b-form-group>
            </b-col>


            <b-col md="12" v-if="getLoggedInUser.user_type == 'super_admin' ">
              <b-form-group label-for="country">
                <template #label>
                  Country <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="country"
                >
                  <v-select @input="fetchCities()" label="name" v-model="country" placeholder="Select Option" :options="countrys" :reduce="option => option.id" autoscroll :clearable="true"></v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <span class="text-colorBlack"
                ></span
              >
              <b-form-group class="mb-0">
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="getLoggedInUser.user_type == 'super_admin' || getLoggedInUser.user_type == 'country_admin'">
              <b-form-group label-for="city">
                <template #label>
                  City <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="city"
                >
                  <v-select :disabled="country === null ? true:false" @input="fetchInstitutes" label="name" v-model="city" placeholder="Select Option" :options="citys" :reduce="option => option.id" autoscroll :clearable="true"></v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <span class="text-colorBlack"
                ></span
              >
              <b-form-group class="mb-0">
              </b-form-group>
            </b-col>


            <b-col md="12">
              <b-form-group label-for="institute">
                <template #label>
                  Institute <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="institute"
                >
                  <v-select :disabled="city === null ? true:false" label="name" v-model="institute" placeholder="Select Option" :options="institutes" :reduce="option => option.id" autoscroll :clearable="true"></v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <span class="text-colorBlack"
                ></span
              >
              <b-form-group class="mb-0">
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label-for="status">
                <template #label>
                  Status <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="status"
                >
                  <v-select label="name" v-model="status" placeholder="Select Option" :options="statuses" :reduce="option => option.id" autoscroll :clearable="true"></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <span class="text-colorBlack"
                ></span
              >
              <b-form-group class="mb-0">
              </b-form-group>
            </b-col>
          </b-form-row>
          
        </b-form>
      </validation-observer>
      <b-form-group class="text-right">
        <b-button block type="submit" variant="colorBlue" @click="validationForm">
          Submit
        </b-button>
      </b-form-group>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  mixins: [util, togglePasswordVisibility],
  props: {
    user: Object
  },
  data() {
    return {
      required,
      username: "",
      password: "Default@123",
      name: "",
      email: "",
      regex: /^[a-zA-Z ]*$/,
      user_types: [],
      statuses: [
        {
          name: 'Active',
          id: true
        },
        {
          name: 'Deactive',
          id: false
        },
      ],
      user_type: "",
      country: "",
      countrys: [],
      city: "",
      citys: [],
      institute: "",
      institutes: [],
      status: "",
    };
  },
  methods: {
    ...mapActions({
      updateUser: "appData/updateUser",
      getCountryDropDown: "appData/getCountryDropDown",
      getCityDropDown: "appData/getCityDropDown",
      getSchoolDropDown: "appData/getSchoolDropDown",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    
    async fetchCities(){
      this.citys = [];
      this.city = null;
      this.institutes = [];
      this.institute = null;
      const citys = await this.getCityDropDown();
      this.citys = citys.data;

      this.citys = this.citys.filter(city => city.country === this.country); 
    },
    async fetchInstitutes(){
      this.institute = [];
      this.institute = null;
      const institutes = await this.getSchoolDropDown();
      this.institutes = institutes.data;

      this.institutes = this.institutes.filter(institute => institute.city === this.city);
    },
    async validationForm() {
      const success = await this.$refs.editUserFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        const res = await this.updateUser({
          payload: {
          username: this.username,
          password: this.password,
          name: this.name,
          email: this.email,
          user_type: this.user_type,
          country: this.country.id,
          city: this.city.id,
          institute: this.institute.id,
          status: this.status,
          created_by: this.getLoggedInUser.id,
          updated_by: this.getLoggedInUser.id,
        },
        pk: this.user.id        
        });
        if (res.status === 200) {
          this.$swal({
            title: "User Updated successfully",
            icon: "success",
          });
          this.$nextTick(() => {
            this.$refs.editUserSidebar.hide();
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    async sidebarShow() {
      const countrys = await this.getCountryDropDown();
      this.countrys = countrys.data;

      const citys = await this.getCityDropDown();
      this.citys = citys.data;

      const institutes = await this.getSchoolDropDown();
      this.institutes = institutes.data;

      if (this.user){
        this.username = this.user.username;
        this.name = this.user.name;
        this.email = this.user.email;
        this.user_type = this.user.user_type;
        this.country = this.countrys.filter(country => country.id === this.user.country);
        this.city = this.citys.filter(city => city.id === this.user.city);
        this.institute = this.institutes.filter(institute => institute.id === this.user.institute);
        this.status = this.user.status;
      }

      switch (this.getLoggedInUser.user_type) {
        case 'super_admin':
          this.user_types = [
            {
              name: 'Country Admin',
              id: 'country_admin'
            },
            {
              name: 'City Admin',
              id: 'city_admin'
            },
            {
              name: 'Institute Admin',
              id: 'institute_admin'
            },
          ];
          break;

        case 'country_admin':
          this.user_types = [
            {
              name: 'City Admin',
              id: 'city_admin'
            },
            {
              name: 'Institute Admin',
              id: 'institute_admin'
            },
          ];
          break;

        case 'city_admin':
          this.user_types = [
            {
              name: 'Institute Admin',
              id: 'institute_admin'
            },
          ];
          break;

        default:
          this.user_types = [];
          break;
      }
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>

<style></style>
